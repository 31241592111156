import { Paper, Typography, Link } from '@material-ui/core';
import * as React from 'react';

import { IAdAccount, IPage, IPost } from '../../../lib/FBInsights';
import styled from '../../../lib/styled-components';
import { extendedTheme } from '../../../lib/theme';

export interface IProps {
  page: IPage;
  adAccount: IAdAccount;
  totalAudienceEngagement?: number | null;
  averageConversionRate?: number | null;
  topThreePosts?: IPost[];
}
interface IState {
  resultsByAgeAndGender: {};
}
export class PerformanceOverview extends React.Component<IProps, IState> {
  state: IState = {
    resultsByAgeAndGender: {}
  };

  componentDidMount() {
    this.loadData();
  }

  async loadData() {
    const { page, adAccount } = this.props;
  }

  public render() {
    return (
      <React.Fragment>
        <Typography variant="h6" gutterBottom>
          Performance Overview
        </Typography>
        <StyledPaper>
          <SingleBox>
            <SingleboxTitle variant="subtitle2">Total Audience Engagement</SingleboxTitle>
            <Typography variant="h1">{this.props.totalAudienceEngagement}</Typography>
          </SingleBox>
          <SingleBox>
            <SingleboxTitle variant="subtitle2">Result Rate</SingleboxTitle>
            <Typography variant="h1">
              {Math.round(100 * (this.props.averageConversionRate || 0))}%
            </Typography>
          </SingleBox>
          <Posts>
            <PostsTitle variant="h4" gutterBottom={true}>
              Most Engaging Posts
            </PostsTitle>
            {this.props.topThreePosts &&
              this.props.topThreePosts.map((post, index) => (
                <PostLine
                  key={`post-${index}`}
                  href={post.permalink_url}
                  variant="body1"
                  target="_blank"
                  rel="noopener"
                  color="inherit"
                >
                  <Message title={post.message}>{post.message}</Message>
                  <Type>{post.type}</Type>
                </PostLine>
              ))}
          </Posts>
          <br />
        </StyledPaper>
      </React.Fragment>
    );
  }
}

const SingleboxTitle = styled(Typography)`
  opacity: 0.8;
`;

const PostsTitle = styled(Typography)`
  padding: 0 32px;
  opacity: 0.8;
`;

const PostLine = styled(Link)`
  ::before {
    counter-increment: post;
    content: counter(post);
    margin-right: 16px;
  }
  :nth-of-type(2) {
    border-top: 1px solid ${extendedTheme.borderColor};
    border-bottom: 1px solid ${extendedTheme.borderColor};
  }

  flex: 1;
  display: flex;
  padding: 19px 32px;
`;

const Message = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 9 9;
`;

const Type = styled.span`
  margin-left: 24px;
  flex: 3 3;
  text-align: right;
  text-transform: capitalize;
  opacity: 0.8;
`;

const SingleBox = styled.div`
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px;
  border-right: 1px solid ${extendedTheme.borderColor};
`;

const Posts = styled.div`
  flex: 2 2 128px;
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  min-width: 0px;
  counter-reset: post;
`;

const StyledPaper = styled(Paper)`
  display: flex;
`;
