import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import { AuthRoute } from './components/molecules/AuthRoute';
import { SecuredRoute } from './components/molecules/SecuredRoute';
import Provider from './components/organisms/Provider';
import { LogIn } from './components/templates/Authentication/LogIn';
import { SignUp } from './components/templates/Authentication/SignUp';
import { Dashboard } from './components/templates/Dashboard';
import { SecuredLayout } from './components/templates/Layouts/SecuredLayout';
import { AppState } from './contexts/AppState';
import { appTheme } from './lib/theme';

class App extends Component {
  render() {
    return (
      <Provider>
        <MuiThemeProvider theme={appTheme}>
          <BrowserRouter>
            <Fragment>
              <CssBaseline />
              <Switch>
                <AuthRoute exact path="/login" component={LogIn} />
                <AuthRoute exact path="/signup" component={SignUp} />
                <Route path="/">
                  <SecuredLayout>
                    <Switch>
                      <SecuredRoute
                        exact
                        path="/"
                        render={() => {
                          return <Redirect to="/dashboard" />;
                        }}
                      />
                      <SecuredRoute
                        path="/dashboard"
                        render={props => (
                          <AppState.Consumer>
                            {appState => <Dashboard {...props} {...appState} />}
                          </AppState.Consumer>
                        )}
                      />
                      {/* <SecuredRoute path="/quizzes" component={Campaigns} /> */}
                      <SecuredRoute render={() => <Redirect to="/dashboard" />} />
                    </Switch>
                  </SecuredLayout>
                </Route>
              </Switch>
            </Fragment>
          </BrowserRouter>
        </MuiThemeProvider>
      </Provider>
    );
  }
}

export default App;
