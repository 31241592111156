import React from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';

import { auth0Client } from '../../lib/Auth';

function randomString() {
  return Math.random()
    .toString(36)
    .substring(3);
}

export const AuthRoute = ({ component, children, render, ...rest }: RouteProps) => {
  const Component = component as React.ComponentClass<RouteProps>;
  return (
    <Route
      {...rest}
      render={props => {
        if (auth0Client.isAuthenticated()) {
          return <Redirect to="/" />;
        }
        return children || (render && render(props)) || <Component {...props} />;
      }}
    />
  );
};
