import * as Sentry from '@sentry/browser';
import React, { Component, ErrorInfo } from 'react';

interface IState {
  error: Error | null;
}

export class ErrorBoundary extends Component {
  state: IState = {
    error: null
  };

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ error });
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.error) {
      return (
        <React.Fragment>
          <button onClick={() => Sentry.showReportDialog()}>Report Feedback</button>;
          {this.props.children}
        </React.Fragment>
      );
    } else {
      return this.props.children;
    }
  }
}
