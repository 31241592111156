import { createContext } from 'react';

interface IAppState {
  settingsOpen: boolean;
  openSettings: (bool?: boolean) => void;
}

export const AppState = createContext<IAppState>({
  settingsOpen: false,
  openSettings: bool => {}
});
