import styled from '../../lib/styled-components';
import { Button } from '@material-ui/core';
import { appTheme } from '../../lib/theme';

export const OutlinedButton = styled(Button).attrs({
  variant: 'outlined',
  classes: { label: 'labelStyles' }
})`
  border-color: ${appTheme.palette.primary.main};
  border-radius: 0px;
  padding: 10px 16px;

  .labelStyles {
    line-height: normal;
  }
`;
