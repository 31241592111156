import { Attr, BelongsTo, HasMany, Model } from 'spraypaint';

import { Answer, LeadGeneration, Quiz } from './';
import ApplicationRecord from './ApplicationRecord';

export enum EQuestionType {
  LeadGeneration = 'LeadGeneration',
  MultipleChoice = 'MultipleChoice'
}

@Model()
export class Question extends ApplicationRecord {
  static jsonapiType = 'questions';

  @BelongsTo() quiz: Quiz;
  @HasMany() answers: Answer[];
  @HasMany() leadGenerations: LeadGeneration[];

  @Attr() id: string;
  @Attr() prompt: string;
  @Attr() position: number;
  @Attr()
  image: {
    original: string | null;
  };
  @Attr() type: EQuestionType;
}
