import { Formik, FormikActions } from 'formik';
import * as React from 'react';
import { RouteComponentProps, Redirect } from 'react-router-dom';

import { auth0Client } from '../../../lib/Auth';
import { ILogInFormValues, LogInForm } from './LogInForm';

export interface ILogInProps extends RouteComponentProps {}

export interface ILogInState {}

export class LogIn extends React.Component<ILogInProps, ILogInState> {
  handleSubmit = (
    values: ILogInFormValues,
    { setSubmitting, setStatus }: FormikActions<ILogInFormValues>
  ) => {
    auth0Client
      .signIn({
        email: values.email,
        password: values.password,
        state: this.props.location.state
      })
      .then(redirectTo => {
        this.props.history.replace(redirectTo);
      })
      .catch((error: any) => {
        setSubmitting(false);
        setStatus(error.policy);
      });
  };

  handleFacebookLogin = ({
    setStatus,
    setSubmitting
  }: Pick<FormikActions<ILogInFormValues>, 'setStatus' | 'setSubmitting'>) => () => {
    auth0Client
      .facebookPrompt({ state: this.props.location.state })
      .then(redirectTo => {
        this.props.history.replace(redirectTo);
      })
      .catch((error: any) => {
        setSubmitting(false);
        setStatus(error.policy);
      });
  };

  public render() {
    return (
      <React.Fragment>
        <Formik
          initialValues={{ email: '', password: '' }}
          onSubmit={this.handleSubmit}
          render={props => <LogInForm onFacebookLogin={this.handleFacebookLogin} {...props} />}
        />
      </React.Fragment>
    );
  }
}
