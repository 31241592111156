import * as React from 'react';

import { Link } from '@material-ui/core';
import { ReactComponent as Logo } from '../../../components/atoms/logo-h-black.svg';
import styled from '../../../lib/styled-components';
import { appTheme } from '../../../lib/theme';
import { PaddedPaper } from '../../atoms/PaddedPaper';

interface IProps {
  children: React.ReactNode;
  bottomElement: React.ReactNode;
}

export const AuthLayout: React.SFC<IProps> = ({ children, bottomElement }) => {
  return (
    <Main>
      <StyledLogo />
      <Outline elevation={1}>{children}</Outline>
      {bottomElement}
      <Footer>
        <Link href="/privacy.pdf" target="_blank">
          Privacy Policy
        </Link>
        &nbsp;|&nbsp;
        <Link href="/terms-of-service.pdf" target="_blank">
          Terms of Service
        </Link>
      </Footer>
    </Main>
  );
};

const Footer = styled.div`
  text-align: center;
`;

const StyledLogo = styled(Logo)`
  width: 70%;
  margin: ${appTheme.spacing.unit * 6}px auto 0;
  display: block;
`;

const Main = styled.main`
  width: auto;
  display: block;
  margin-left: ${appTheme.spacing.unit * 3}px;
  margin-right: ${appTheme.spacing.unit * 3}px;
  ${appTheme.breakpoints.up(400 + appTheme.spacing.unit * 3 * 2)} {
    width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const Outline = styled(PaddedPaper)`
  margin-top: ${appTheme.spacing.unit * 4}px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
