import { Attr, BelongsTo, HasMany, Model } from 'spraypaint'

import { Answer, Quiz } from '.'
import ApplicationRecord from './ApplicationRecord'

@Model()
export class Result extends ApplicationRecord {
  static jsonapiType = "results";

  @HasMany() answers: Answer[];
  @BelongsTo() quiz: Quiz;

  @Attr() id: string;
  @Attr() leadGenerationEntries: string[];
}
