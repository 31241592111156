import * as React from 'react';
import { DashboardContainer } from './DashboardContainer';
import { FacebookLogin, IFacebookLoginResponse } from '../../molecules/FacebookLogin';
import { Paper, Typography, FormControl, InputLabel, Select, Button } from '@material-ui/core';
import { IAdAccount, IPage, getAccounts } from '../../../lib/FBInsights';
import styled from '../../../lib/styled-components';
import { PaddedPaper } from '../../atoms/PaddedPaper';
import { appTheme } from '../../../lib/theme';
import Cookies from 'js-cookie';

interface IProps {
  settingsOpen: boolean;
  openSettings: (bool?: boolean) => void;
}

interface IState {
  facebookToken?: string;
  userID?: string;
  adAccounts: IAdAccount[];
  adAccount?: IAdAccount;
  page?: IPage;
  readyToContinue: boolean;
  previousSettings?: FacebookSettings;
}

interface FacebookSettings {
  adAccountId: string;
  pageId: string;
}

const FB_SETTINGS_COOKIE = 'fi-fb-settings';

export class Dashboard extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const previousSettings: FacebookSettings = Cookies.getJSON(
      FB_SETTINGS_COOKIE
    ) as FacebookSettings;

    this.state = {
      adAccounts: [],
      readyToContinue: false,
      previousSettings
    };
  }

  // HANDLERS
  loginHandler = ({ accessToken: facebookToken, userID }: IFacebookLoginResponse) => {
    this.setState({ facebookToken, userID }, this.loadData);
  };

  handleSelect = (source: { id: string }[], stateObject: string) => (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = source.find((p: { id: string }) => p.id === event.target.value);

    let page;
    if (stateObject === 'adAccount') {
      const adAccount = value as IAdAccount;
      if (adAccount.promote_pages.length === 1) {
        page = adAccount.promote_pages[0];
      }
    }
    this.setState({ page, [stateObject]: value } as any);
  };

  handleClickContinue = () => {
    const { adAccount, page } = this.state;
    if (adAccount && page) {
      Cookies.set(FB_SETTINGS_COOKIE, { adAccountId: adAccount.id, pageId: page.id });
      this.setState({ readyToContinue: true });
      this.props.openSettings(false);
    }
  };

  async loadData() {
    const { facebookToken, userID, previousSettings } = this.state;
    getAccounts(facebookToken!, userID!).then(adAccounts => {
      let page, adAccount;
      let readyToContinue = false;

      if (previousSettings) {
        adAccount = adAccounts.find(
          account => account.id === previousSettings.adAccountId
        ) as IAdAccount;
        page = adAccount.promote_pages.find(p => p.id === previousSettings.pageId) as IPage;
        if (page && adAccount) {
          readyToContinue = true;
        }
      }
      this.setState({ adAccounts, adAccount, page, readyToContinue });
    });
  }

  renderLoginPrompt = (
    <FloatingPaper>
      <ToGetStarted variant="h5" align="center">
        To get started,{' '}
      </ToGetStarted>
      <FacebookLogin loginHandler={this.loginHandler}>Continue with facebook</FacebookLogin>
    </FloatingPaper>
  );

  renderSelectAccounts = () => {
    const { page, adAccounts, adAccount } = this.state;

    return (
      <FloatingPaper>
        <StyledFormControl margin="normal">
          <InputLabel htmlFor="facebook-adaccount-selector">Facebook Ad Account</InputLabel>
          <Select
            native
            value={adAccount ? adAccount.id : ''}
            onChange={this.handleSelect(adAccounts, 'adAccount')}
            inputProps={{
              name: 'facebookAdAccount',
              id: 'facebook-adaccount-selector'
            }}
          >
            <option value="" disabled />
            {adAccounts.map(adAccount => (
              <option key={`adAccount-${adAccount.id}`} value={adAccount.id}>
                {adAccount.name}
              </option>
            ))}
          </Select>
        </StyledFormControl>
        <StyledFormControl margin="normal">
          <InputLabel htmlFor="facebook-page-selector">Facebook Page</InputLabel>
          <Select
            native
            disabled={!adAccount}
            value={page ? page.id : ''}
            onChange={adAccount && this.handleSelect(adAccount.promote_pages, 'page')}
            inputProps={{
              name: 'facebookPage',
              id: 'facebook-page-selector'
            }}
          >
            <option value="" disabled />
            {adAccount &&
              adAccount.promote_pages.map(page => (
                <option key={`page-${page.id}`} value={page.id}>
                  {page.name}
                </option>
              ))}
          </Select>
        </StyledFormControl>
        <LetsGoButton variant="contained" color="primary" onClick={this.handleClickContinue}>
          Let's Go!
        </LetsGoButton>
      </FloatingPaper>
    );
  };

  render() {
    const { facebookToken, userID, adAccount, adAccounts, page, readyToContinue } = this.state;

    if (facebookToken && userID) {
      if (page && adAccount && readyToContinue && !this.props.settingsOpen) {
        return (
          <DashboardContainer
            selectedAdAccount={adAccount}
            selectedPage={page}
            facebookToken={facebookToken}
          />
        );
      } else if (adAccounts) {
        return this.renderSelectAccounts();
      }
    } else {
      return this.renderLoginPrompt;
    }
  }
}

const StyledFormControl = styled(FormControl)`
  /* &:not(:first-of-type) {
    margin-top: 24px;
  } */
`;

const LetsGoButton = styled(Button)`
  margin-top: 32px;
`;
const FloatingPaper = styled(PaddedPaper)`
  display: flex;
  flex-direction: column;
  margin: 15vh auto 0;
  min-width: 318px;
`;

const ToGetStarted = styled(Typography)`
  margin-bottom: ${appTheme.spacing.unit * 3}px;
`;
