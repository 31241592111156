import { Attr, BelongsTo, HasMany, Model } from 'spraypaint';

import { Offer, Question, Result, User } from './';
import ApplicationRecord from './ApplicationRecord';

export enum EQuizFormat {
  PersonalityQuiz = 'PersonalityQuiz',
  GradedQuiz = 'GradedQuiz',
  OceanQuiz = 'OceanQuiz'
}

export enum EQuizSortAttributes {
  UpdatedAt = 'updatedAt'
}

@Model()
export class Quiz extends ApplicationRecord {
  static jsonapiType = 'quizzes';

  @HasMany() questions: Question[];
  @HasMany() offers: Offer[];
  @HasMany() results: Result[];
  @BelongsTo() user: User;

  @Attr() answerButtonColor: string;
  @Attr() answerFontColor: string;
  @Attr() answerFontFamily: string;
  @Attr() answersHolderColor: string;
  @Attr() blockAds: boolean;
  @Attr() buttonFontColor: string;
  @Attr() buttonFontFamily: string;
  @Attr({ persist: false })
  createdAt: string;
  @Attr() customAd: string;
  @Attr() description: string;
  @Attr() format: EQuizFormat;
  @Attr() hideLogo: boolean;
  @Attr() id: string;
  @Attr()
  image: {
    original: string | null;
  };
  @Attr() imageBlurColor: string;
  @Attr() leadGenerationAllowSkip: boolean;
  @Attr() leadGenerationSkipText: string;
  @Attr() leadGenerationButtonText: string;
  @Attr() leadGenerationPromptText: string;
  @Attr() leadGenerationConsentText: string;
  @Attr() leadGenerationFields: ILeadGenerationField[];
  @Attr() notes: string;
  @Attr() paragraphFontColor: string;
  @Attr() paragraphFontFamily: string;
  @Attr() progressFontColor: string;
  @Attr() progressFontFamily: string;
  @Attr() published: boolean;
  @Attr() requireLeadGenerationForResults: boolean;
  @Attr() titleFontColor: string;
  @Attr() titleFontFamily: string;
  @Attr() title: string;
  @Attr({ persist: false })
  updatedAt: string;
}

export enum ELeadGenerationTypes {
  checkbox = 'Checkbox',
  dropdown = 'Dropdown',
  text = 'Text'
}

interface ILeadGenerationField {
  title: string;
  type: ELeadGenerationTypes;
  placeholder: string;
  options?: string[];
}
