import React, { Component, Fragment } from 'react';

import { Header } from '../../organisms/Header';
import { Sidebar } from '../../organisms/Sidebar';
import styled from '../../../lib/styled-components';
import { withTheme } from '@material-ui/core';
import { Footer } from '../../organisms/Footer';

export class SecuredLayout extends Component {
  render() {
    return (
      <Fragment>
        <Header />
        <Content>{this.props.children}</Content>
        <Footer />
      </Fragment>
    );
  }
}

const Content = withTheme()(styled.main`
  flex-grow: 1;
  padding: ${props => props.theme.spacing.unit * 3}px 0;
  max-width: 1174px;
  margin: 0 auto;
`);
