import { Attr, HasMany, Model } from 'spraypaint';

import { Quiz } from './';
import ApplicationRecord from './ApplicationRecord';

@Model()
export class User extends ApplicationRecord {
  static jsonapiType = 'users';

  @HasMany() quizzes: Quiz[];

  @Attr() id: string;
  @Attr({ persist: false })
  email: string;
  @Attr() company: string;
  @Attr() name: string;
  @Attr({ persist: false }) fb_access_token: string;
}
