import { Model, SpraypaintBase, MiddlewareStack } from 'spraypaint';
import { auth0Client } from '../lib/Auth';
// import { kQMSAuthToken } from 'services/auth'
// declare const REACT_APP_FURTHER_INSIGHTS_API_URL: string;
const { REACT_APP_FURTHER_INSIGHTS_API_URL } = process.env;

@Model()
class ApplicationRecord extends SpraypaintBase {
  static baseUrl = REACT_APP_FURTHER_INSIGHTS_API_URL as string;
  static apiNamespace = '/api/v1';
  static jwtStorage = 'FI_TOKEN';
  static sync = true;
  static generateAuthHeader = (token: string) => {
    return `Bearer ${auth0Client.getAccessToken()}`;
  };

  static load(id: string): SpraypaintBase {
    const model = new this(this.store.data[`${this.jsonapiType}-${id}`]);

    if (model && model.id) {
      model.isPersisted = true;
    }
    return model;
  }

  fullyLoaded: boolean = false;

  // delete = (): Promise<any> => (this.isPersisted ? this.destroy() : Promise.resolve());
}

let middleware = new MiddlewareStack();
middleware.afterFilters.push((response, json) => {
  if (response.status === 401) {
    auth0Client.signOut();
    window.location.href = '/login';
  }
});

ApplicationRecord.middlewareStack = middleware;

export default ApplicationRecord;
