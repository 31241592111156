import { Attr, Model } from 'spraypaint';

import ApplicationRecord from './ApplicationRecord';

@Model()
export class Session extends ApplicationRecord {
  static jsonapiType = 'sessions';

  @Attr({ persist: false }) facebookToken: string;
  @Attr({ persist: false }) facebookUserId: string;
}
