import { Attr, BelongsTo, Model } from "spraypaint";

import { Offer, Question } from "./";
import ApplicationRecord from "./ApplicationRecord";

@Model()
export class Answer extends ApplicationRecord {
  static jsonapiType = "answers";

  @BelongsTo() question: Question;
  @BelongsTo() offer: Offer;

  @Attr() id: string;
  @Attr() position: number;
  @Attr() points: number;
  @Attr() isCorrect: boolean;
  @Attr() text: string;
  @Attr() createdAt: string;
  @Attr() updatedAt: string;
  @Attr() questionId: string;
}
