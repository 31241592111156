import {
  AppBar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  withTheme
} from '@material-ui/core';
import { AppBarProps } from '@material-ui/core/AppBar';
import { IconButtonProps } from '@material-ui/core/IconButton';
import { ToolbarProps } from '@material-ui/core/Toolbar';
import { TypographyProps } from '@material-ui/core/Typography';
import { AccountCircle } from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/Menu';
import React, { Component, Fragment } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { ReactComponent as Logo } from '../atoms/lighttheme.svg';
import { auth0Client } from '../../lib/Auth';
import styled, { css } from '../../lib/styled-components';
import { drawerWidth } from './Sidebar';
import { OutlinedButton } from '../atoms/OutlineButton';
import { appTheme } from '../../lib/theme';
import { AppState } from '../../contexts/AppState';

interface IProps extends RouteComponentProps<{}> {}

interface IState {}

class HeaderBase extends Component<IProps, IState> {
  handleSignOut = () => {
    auth0Client.signOut();
    this.props.history.push('/login');
  };

  render() {
    return (
      <Fragment>
        <StyledHeader>
          <HeaderContent>
            <StyledToolbar disableGutters>
              <Title component="h1" variant="h6" color="inherit" noWrap>
                <StyledLogoLink to="/">
                  <Logo height="48px" />
                </StyledLogoLink>
              </Title>
              <AppState.Consumer>
                {({ openSettings }) => (
                  <Button variant="text" onClick={() => openSettings()}>
                    Settings
                  </Button>
                )}
              </AppState.Consumer>
              <StyledOutlinedButton onClick={this.handleSignOut}>SIGN OUT</StyledOutlinedButton>
            </StyledToolbar>
            {/* <Typography variant="h2">Mercedes Benz</Typography>
            <Greeting variant="body1">Hi, Ted</Greeting> */}
          </HeaderContent>
        </StyledHeader>
      </Fragment>
    );
  }
}

// const SettingsLink = (props: any) => <Link to="/settings" {...props} />;

const StyledOutlinedButton = styled(OutlinedButton)`
  margin-left: ${appTheme.spacing.unit * 3}px;
`;

const StyledToolbar = styled(Toolbar)`
  margin: 24px 0 36px;
`;

const HeaderContent = styled.div`
  margin: 0 auto;
  max-width: 1174px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Greeting = styled(Typography)`
  margin: 24px 0px;
`;
const StyledHeader = styled.header`
  position: relative;
  background: #fff;
`;

const StyledLogoLink = styled(Link)`
  text-decoration: none;
`;

const Title = withTheme()(styled(Typography)`
  flex-grow: 1;
`) as React.FC<TypographyProps>;

export const Header = withRouter(HeaderBase);
