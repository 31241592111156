import React, { Component } from 'react';

import { AppState } from '../../contexts/AppState';

interface IState {
  settingsOpen: boolean;
  openSettings: (bool?: boolean) => void;
}

class Provider extends Component<{}, IState> {
  state = {
    settingsOpen: false,
    openSettings: (bool?: boolean) => {
      this.setState(state => ({
        settingsOpen: bool === undefined ? !state.settingsOpen : bool
      }));
    }
  };

  render() {
    const { children } = this.props;

    return <AppState.Provider value={this.state}>{children}</AppState.Provider>;
  }
}
export default Provider;
