import React, { Component } from 'react';
import styled from '../../lib/styled-components';

declare global {
  interface Window {
    FB: any;
  }
}

export interface IFacebookLoginResponse {
  userID: string;
  accessToken: string;
  name?: string;
  email?: string;
}

interface IProps {
  loginHandler: (response: IFacebookLoginResponse) => void;
}

export class FacebookLogin extends Component<IProps> {
  FB: any;
  componentDidMount() {
    if (window.FB) {
      this.initializeFacebookLogin();
    } else {
      document.addEventListener('FBObjectReady', this.initializeFacebookLogin);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('FBObjectReady', this.initializeFacebookLogin);
  }

  componentDidUpdate() {
    this.FB && this.FB.XFBML.parse();
  }

  initializeFacebookLogin = () => {
    this.FB = window.FB;
    this.FB.Event.subscribe('auth.login', (response: any) => {
      this.facebookLoginHandler(response);
    });
    this.FB.Event.subscribe('auth.statusChange', this.facebookLoginHandler);
    this.checkLoginStatus();
  };

  checkLoginStatus = () => {
    this.FB.getLoginStatus(this.facebookLoginHandler);
  };

  facebookLogin = () => {
    if (!this.FB) return;

    this.FB.login(this.checkLoginStatus, {
      scope: 'email,read_insights,manage_pages,ads_read,ads_management'
    });
  };

  facebookLoginHandler = ({
    authResponse,
    status
  }: {
    authResponse: IFacebookLoginResponse;
    status: 'connected' | 'unknown';
  }) => {
    status === 'connected' && this.props.loginHandler(authResponse);
  };

  render() {
    return (
      <FacebookButton
        className="fb-login-button"
        data-size="large"
        data-button-type="continue_with"
        data-auto-logout-link="false"
        data-use-continue-as="false"
      />
    );
  }
}

const FacebookButton = styled.div`
  height: 40px;
  width: 254px;
`;
