import { Button, FormHelperText, Typography, withTheme } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import { TypographyProps } from '@material-ui/core/Typography';
import { Field, Form, FormikActions, FormikProps } from 'formik';
import { TextField } from 'formik-material-ui';
import * as React from 'react';
import { Link } from 'react-router-dom';

import styled from '../../../lib/styled-components';
import { appTheme } from '../../../lib/theme';
import { AuthLayout } from '../Layouts/AuthLayout';

export interface ILogInFormValues {
  email: string;
  password: string;
}

const SubButton = styled(Button)`
  display: flex;
  margin: ${appTheme.spacing.unit}px auto;
` as React.FC<ButtonProps>;

const MyLink = (props: any) => <Link to="/signup" {...props} />; //eslint-disable-line
const createAnAccount = <SubButton component={MyLink}>Create an account</SubButton>;

interface IProps extends FormikProps<ILogInFormValues> {
  onFacebookLogin: (
    props: Pick<FormikActions<ILogInFormValues>, 'setStatus' | 'setSubmitting'>
  ) => () => void;
}

const LogInForm = ({ onFacebookLogin, isSubmitting, setSubmitting, setStatus, status }: IProps) => (
  <AuthLayout bottomElement={createAnAccount}>
    <LogInTitle variant="h4">Log In</LogInTitle>
    <Form>
      <Field
        component={TextField}
        type="email"
        name="email"
        variant="outlined"
        label="email"
        fullWidth
        margin="normal"
        autoFocus
        required
      />
      <Field
        component={TextField}
        type="password"
        name="password"
        variant="outlined"
        label="password"
        fullWidth
        margin="normal"
        required
      />
      {status && <FormHelperText>{status}</FormHelperText>}
      <LogInButton type="submit" variant="contained" color="primary" disabled={isSubmitting}>
        Log In
      </LogInButton>
      <StyledOr variant="body2">OR</StyledOr>
      <Facebook onClick={onFacebookLogin({ setStatus, setSubmitting })}>
        Continue with facebook
      </Facebook>
    </Form>
  </AuthLayout>
);

const StyledOr = styled(Typography)`
  text-align: center;
  margin: ${appTheme.spacing.unit * 2}px auto;
`;

const LogInTitle = styled(Typography)`
  margin-bottom: ${appTheme.spacing.unit * 3}px;
` as React.FC<TypographyProps>;

const LogInButton = styled(Button)`
  margin: ${appTheme.spacing.unit * 3}px auto 0;
  width: 75%;
  display: flex;
` as React.FC<ButtonProps>;

const Facebook = styled(LogInButton)`
  margin: 0 auto 0;
  color: white;
  background: #4267b2;
`;

const themed = withTheme()(LogInForm);
export { themed as LogInForm };
