import React from 'react';
import { Toolbar, Typography, Link } from '@material-ui/core';
import styled from '../../lib/styled-components';
import { OutlinedButton } from '../atoms/OutlineButton';
import { appTheme } from '../../lib/theme';

export const Footer = () => (
  <StyledFooter>
    <StyledToolbar disableGutters>
      <Buffer />
      <Typography variant="body1">{copyrightString}</Typography>
      <span>
        <Link href="/privacy.pdf" target="_blank">
          Privacy Policy
        </Link>
        &nbsp;|&nbsp;
        <Link href="/terms-of-service.pdf" target="_blank">
          Terms of Service
        </Link>
      </span>
    </StyledToolbar>
  </StyledFooter>
);

const Buffer = styled.div`
  width: 214px;
`;

const ContactUsButton = styled(OutlinedButton)`
  margin-left: ${appTheme.spacing.unit * 3}px;
`;

const StyledToolbar = styled(Toolbar)`
  max-width: 1174px;
  margin: 0 auto;
  justify-content: space-between;
`;

const StyledFooter = styled.footer`
  background: #fff;
`;

const copyrightString = `Copyright © ${new Date().getFullYear()}, Further Insights`;
