import { Grid, Paper, Typography } from '@material-ui/core';
import * as React from 'react';

import { IByAgeGender, topAudienceSegments } from '../../../lib/FBInsights';
import styled from '../../../lib/styled-components';
import { appTheme, extendedTheme } from '../../../lib/theme';
import { ReactComponent as GreenCheck } from '../../atoms/green-check.svg';
import { ReactComponent as RedX } from '../../atoms/red-ex.svg';

export interface IProps {
  adAccountConversionRateByAgeGender?: IByAgeGender;
  engagementRateByAgeGender?: IByAgeGender;
}

export const TopAudienceSegments = ({
  engagementRateByAgeGender,
  adAccountConversionRateByAgeGender
}: IProps) => {
  let segments;
  if (engagementRateByAgeGender && adAccountConversionRateByAgeGender) {
    segments = topAudienceSegments(engagementRateByAgeGender, adAccountConversionRateByAgeGender);
  }
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Top Audience Segments
      </Typography>
      <StyledPaper>
        <Grid container>
          {segments && (
            <React.Fragment>
              <SingleBox item sm={6}>
                <SingleboxTitle variant="body1">Segment A</SingleboxTitle>
                <DemographicLabel variant="h3">
                  {segments['A'].gender}
                  <br />
                  {segments['A'].age}
                </DemographicLabel>
                <LabelText variant="body1">
                  <GreenCheck />
                  Most Engaged
                </LabelText>
                <LabelText variant="body1">
                  <GreenCheck />
                  Highest Conversion
                </LabelText>
              </SingleBox>
              <SingleBox item sm={6}>
                <SingleboxTitle variant="body1">Segment B</SingleboxTitle>
                <DemographicLabel variant="h3">
                  {segments['B'].gender}
                  <br />
                  {segments['B'].age}
                </DemographicLabel>{' '}
                <LabelText variant="body1">
                  <RedX /> Less Engaged
                </LabelText>
                <LabelText variant="body1">
                  <GreenCheck /> High Conversion
                </LabelText>
              </SingleBox>
              <SingleBox item sm={6}>
                <SingleboxTitle variant="body1">Segment C</SingleboxTitle>
                <DemographicLabel variant="h3">
                  {segments['C'].gender}
                  <br />
                  {segments['C'].age}
                </DemographicLabel>
                <LabelText variant="body1">
                  <GreenCheck /> More Engaged
                </LabelText>
                <LabelText variant="body1">
                  <RedX /> Lower Conversion
                </LabelText>
              </SingleBox>
              <SingleBox item sm={6}>
                <SingleboxTitle variant="body1">Segment D</SingleboxTitle>
                <DemographicLabel variant="h3">
                  {segments['D'].gender}
                  <br />
                  {segments['D'].age}
                </DemographicLabel>
                <LabelText variant="body1">
                  <RedX /> Least Engaged
                </LabelText>
                <LabelText variant="body1">
                  <RedX /> Lowest Conversion
                </LabelText>
              </SingleBox>
            </React.Fragment>
          )}
        </Grid>
        <br />
      </StyledPaper>
    </React.Fragment>
  );
};

const DemographicLabel = styled(Typography)`
  flex: 2 1;
`;

const LabelText = styled(Typography)`
  opacity: 0.8;
  svg {
    vertical-align: middle;
    left: -5px;
    top: -1px;
    position: relative;
  }
`;

const SingleboxTitle = styled(Typography)`
  flex: 1 1;
  opacity: 0.5;
`;

const SingleBox = styled(Grid)`
  ${appTheme.breakpoints.up('sm')} {
    &:nth-child(-n + 2) {
      border-bottom: 1px solid ${extendedTheme.borderColor};
    }
    height: 300px;
    display: flex;
    flex-direction: column;
  }
  padding: 32px;
  &:nth-child(odd) {
    border-right: 1px solid ${extendedTheme.borderColor};
  }
`;

const StyledPaper = styled(Paper)`
  display: flex;
  flex-wrap: wrap;
  height: 600px;
`;
