import { AdAccount, FacebookAdsApi, Objective, Page, User } from 'facebook-nodejs-business-sdk';
import { subDays } from 'date-fns/esm';

export interface IAdAccount {
  id: string;
  name: string;
  promote_pages: IPage[];
}

export interface IPage {
  id: string;
  name: string;
  access_token: string;
}

export interface IPost {
  id?: string;
  message?: string;
  engagements?: number;
  type?: string;
  permalink_url?: string;
}

export interface IByAgeGender {
  'F.13-17'?: number;
  'F.18-24'?: number;
  'F.25-34'?: number;
  'F.35-44'?: number;
  'F.45-54'?: number;
  'F.55-64'?: number;
  'F.65+'?: number;
  'M.13-17'?: number;
  'M.18-24'?: number;
  'M.25-34'?: number;
  'M.35-44'?: number;
  'M.45-54'?: number;
  'M.55-64'?: number;
  'M.65+'?: number;
  'U.13-17'?: number;
  'U.18-24'?: number;
  'U.25-34'?: number;
  'U.35-44'?: number;
  'U.45-54'?: number;
  'U.55-64'?: number;
  'U.65+'?: number;
}

export async function getAccounts(accessToken: string, userId: string): Promise<IAdAccount[]> {
  FacebookAdsApi.init(accessToken);
  const response: {
    adaccounts: {
      data: [{ id: string; name: string; promote_pages?: { data: IPage[] } }];
    };
  } = await new User(userId).get([
    'adaccounts.fields(name,promote_pages.fields(name,access_token))'
  ]);

  const adAccounts: IAdAccount[] = response.adaccounts.data
    .filter(adaccount => adaccount.promote_pages)
    .map(adaccount => {
      const { promote_pages } = adaccount;

      return {
        ...adaccount,
        promote_pages: promote_pages
          ? promote_pages.data.filter(page => page.access_token)
          : undefined
      } as IAdAccount;
    });

  return adAccounts;
}

export async function pageTopThreeMostEngagingPosts({ id, access_token }: IPage): Promise<IPost[]> {
  FacebookAdsApi.init(access_token);
  const page = new Page(id);
  const response = await page.getPosts([
    'message',
    'type',
    'insights.metric(post_clicks)',
    'permalink_url'
  ]);
  const posts: {
    message: string;
    engagements: number;
    permalink_url: string;
    type: string;
  }[] = response.map(
    (post: {
      message: string;
      permalink_url: string;
      type: string;
      insights: { data: any[] };
    }) => ({
      message: post.message,
      type: post.type,
      engagements: post.insights.data[0].values[0].value,
      permalink_url: post.permalink_url
    })
  );
  return posts.sort((a, b) => b.engagements - a.engagements).slice(0, 3);
}

export async function pageTotalAudienceEngagement({
  id,
  access_token
}: IPage): Promise<number | null> {
  FacebookAdsApi.init(access_token);
  const page = new Page(id);
  const fields: string[] = [];
  const params = {
    metric: ['page_post_engagements'],
    period: 'day',
    date_preset: 'this_year'
  };

  const response: { 0: { values: { value: number; end_time: Date }[] } } = await page.getInsights(
    fields,
    params
  );
  const totalAudienceEngagement =
    (response[0] && response[0].values.reduce((a, x) => a + x.value, 0)) || null;
  return totalAudienceEngagement;
}

const PAGE_ENGAGEMENT_BY_AGE_GENDER_METRIC = 'page_content_activity_by_age_gender_unique';
const PAGE_IMPRESSIONS_BY_AGE_GENDER_METRIC = 'page_impressions_by_age_gender_unique';

export async function pageEngagementRateByAgeGender({
  id,
  access_token
}: IPage): Promise<IByAgeGender> {
  FacebookAdsApi.init(access_token);
  const page = new Page(id);
  const fields: string[] = [];
  const params = {
    metric: [PAGE_ENGAGEMENT_BY_AGE_GENDER_METRIC, PAGE_IMPRESSIONS_BY_AGE_GENDER_METRIC],
    period: 'days_28',
    date_preset: 'last_90d'
    // since: (subDays(new Date(), 10).getTime() / 1000) | 0
  };

  const response: {
    name:
      | typeof PAGE_ENGAGEMENT_BY_AGE_GENDER_METRIC
      | typeof PAGE_IMPRESSIONS_BY_AGE_GENDER_METRIC;
    values: { value: {} }[];
  }[] = await page.getInsights(fields, params);

  const valuesByName = response.reduce(
    (a, x) => ({ ...a, [x.name]: x.values[x.values.length - 1].value }),
    {}
  );

  valuesByName[PAGE_ENGAGEMENT_BY_AGE_GENDER_METRIC] =
    valuesByName[PAGE_ENGAGEMENT_BY_AGE_GENDER_METRIC] || {};
  valuesByName[PAGE_IMPRESSIONS_BY_AGE_GENDER_METRIC] =
    valuesByName[PAGE_IMPRESSIONS_BY_AGE_GENDER_METRIC] || {};

  const ageGenderKeys = ageGenderKeysUnion(Object.values(valuesByName));

  const output = ageGenderKeys.reduce((acc, key) => {
    const ratio =
      (Number(valuesByName[PAGE_ENGAGEMENT_BY_AGE_GENDER_METRIC][key]) || 0) /
      Number(valuesByName[PAGE_IMPRESSIONS_BY_AGE_GENDER_METRIC][key]);

    return {
      ...acc,
      [key]: ratio
    };
  }, {});

  return output;
}

export async function adAccountConversionRate({
  id
}: IAdAccount): Promise<{
  adAccountConversionRateByAgeGender: IByAgeGender;
  adAccountTotalConversionRate: number;
}> {
  const adAccount = new AdAccount(id);
  const params = {
    default_summary: true,
    date_preset: 'lifetime',
    level: 'campaign',
    period: 'days_28',
    breakdowns: ['age', 'gender'],
    limit: 1000
  };
  const insightsFields = [
    'campaign_id',
    'campaign_name',
    'objective',
    'actions',
    'video_10_sec_watched_actions',
    'video_30_sec_watched_actions',
    'reach',
    'impressions',
    'ctr'
  ];

  const response: adResponse[] = await adAccount.getInsights(insightsFields, params);

  const adAccountConversionRateByAgeGender: IByAgeGender = {};
  let totalResults = 0;
  let totalImpressions = 0;

  const campaigns = response.map(r => {
    totalImpressions += Number(r.impressions);
    if (!r.actions) {
      return r;
    }
    const action = r.actions.find(a => a.action_type === ObjectiveToActionType[r.objective]);
    if (action) {
      totalResults += Number(action.value);

      const prevValue = adAccountConversionRateByAgeGender[`${r.gender}:${r.age}`] || {
        results: 0,
        impressions: 0
      };

      const results = prevValue.results + Number(action.value);
      const impressions = prevValue.impressions + Number(r.impressions);

      adAccountConversionRateByAgeGender[`${r.gender[0].toUpperCase()}.${r.age}`] =
        results / impressions;
    }
  });

  return {
    adAccountConversionRateByAgeGender,
    adAccountTotalConversionRate: totalResults / totalImpressions
  };
}

export interface ICity {
  name: string;
  count: number;
  percentage: number;
}

export async function pageTopCities({ id, access_token }: IPage): Promise<ICity[]> {
  FacebookAdsApi.init(access_token);
  const page = new Page(id);
  const fields: string[] = [];
  const params = {
    metric: ['page_content_activity_by_city_unique'],
    period: 'days_28',
    date_preset: 'last_3d'
  };

  const response = await page.getInsights(fields, params);
  let cities: ICity[];
  if (response[0]) {
    const cityObject: { [key: string]: number } = response[0].values.pop().value;
    const totalVisitors = Object.values(cityObject).reduce((acc, count) => acc + count, 0);

    cities = Object.entries(cityObject)
      .sort((a: any, b: any) => {
        return b[1] - a[1];
      })
      .map(([country, count]) => ({ name: country, count, percentage: count / totalVisitors }));
  } else {
    cities = [];
  }

  return cities;
}

const ObjectiveToActionType = {
  APP_INSTALLS: 'app_install',
  BRAND_AWARENESS: '',
  CANVAS_APP_ENGAGEMENT: '',
  CANVAS_APP_INSTALLS: '',
  EVENT_RESPONSES: 'rsvp',
  LEAD_GENERATION: 'lead',
  LINK_CLICKS: 'link_click',
  LOCAL_AWARENESS: '',
  MESSAGES: 'onsite_conversion',
  MOBILE_APP_ENGAGEMENT: '',
  MOBILE_APP_INSTALLS: 'mobile_app_install',
  OFFER_CLAIMS: 'receive_offer',
  PAGE_LIKES: 'like',
  POST_ENGAGEMENT: 'post_engagement',
  PRODUCT_CATALOG_SALES: 'purchase',
  VIDEO_VIEWS: 'video_view',
  WEBSITE_CONVERSIONS: 'offsite_conversion'
};

interface adResponse {
  campaign_id: string;
  campaign_name: string;
  objective: Objective;
  actions: { action_type: string; value: string }[];
  reach: string;
  impressions: string;
  ctr: string;
  date_start: string;
  date_stop: string;
  age: string;
  gender: 'male' | 'female';
}

declare global {
  interface Window {
    FBInsights: any;
  }
}

// return values;
// Change the format for conversions to use this same 'M.55-64' for easier key comparison.
// Use these results in the dashboard to calculate the segments.
// Find the average of the conversion and engagement
// Each amount minus the average
// separate those points into 'quadrants'
// find the hypotenuse length from origin.
// max in each quadrant

export function topAudienceSegments(
  engagementRates: IByAgeGender,
  conversionRates: IByAgeGender
): {
  [K in 'A' | 'B' | 'C' | 'D']: {
    gender: 'Male' | 'Female' | '';
    age: string;
    engaged: string;
    conversion: string;
  }
} {
  const allAgeGenders = ageGenderKeysUnion([engagementRates, conversionRates]);

  const engagementRatesArray: number[] = [];
  const conversionRatesArray: number[] = [];

  allAgeGenders.forEach(key => {
    engagementRates[key] && engagementRatesArray.push(engagementRates[key]);
    conversionRates[key] && conversionRatesArray.push(conversionRates[key]);
  });

  // const engagementMeanAvg =
  //   engagementRatesArray.reduce((a: number, x) => a + x, 0) / engagementRatesArray.length;

  // const conversionMeanAvg =
  //   conversionRatesArray.reduce((a: number, x) => a + x, 0) / conversionRatesArray.length;

  const engagementMedian = median(engagementRatesArray);
  const conversionMedian = median(conversionRatesArray);

  const segmentA = [] as ISegmentPoint[];
  const segmentB = [] as ISegmentPoint[];
  const segmentC = [] as ISegmentPoint[];
  const segmentD = [] as ISegmentPoint[];

  allAgeGenders.forEach(ageGender => {
    const e = engagementRates[ageGender] || 0;
    const c = conversionRates[ageGender] || 0;

    const eX = e - engagementMedian;
    const cY = c - conversionMedian;
    const distance = Math.sqrt(eX * eX + cY * cY);

    const point = { ageGender, eX, cY, distance };

    switch (true) {
      case eX > 0 && cY > 0:
        segmentA.push(point);
        break;
      case eX <= 0 && cY > 0:
        segmentB.push(point);
        break;
      case eX > 0 && cY <= 0:
        segmentC.push(point);
        break;
      case eX <= 0 && cY <= 0:
        segmentD.push(point);
        break;
    }
  });

  return {
    A: { ...segmentWinner(segmentA), engaged: 'Most Engaged', conversion: 'Highest Conversion' },
    B: { ...segmentWinner(segmentB), engaged: 'Least Engaged', conversion: 'Highest Conversion' },
    C: { ...segmentWinner(segmentC), engaged: 'Most Engaged', conversion: 'Lowest Conversion' },
    D: { ...segmentWinner(segmentD), engaged: 'Least Engaged', conversion: 'Lowest Conversion' }
  };
}

function median(values: number[]): number {
  if (values.length === 0) return 0;

  values.sort(function(a, b) {
    return a - b;
  });

  var half = Math.floor(values.length / 2);

  if (values.length % 2) {
    return values[half];
  }

  return (values[half - 1] + values[half]) / 2.0;
}

interface ISegmentPoint {
  ageGender: string;
  eX: number;
  cY: number;
  distance: number;
}

function segmentWinner(points: ISegmentPoint[]): { gender: 'Male' | 'Female' | ''; age: string } {
  if (points.length < 1) {
    return { age: 'Insufficient Data', gender: '' };
  }

  const winner = points.reduce((prevMax, current) =>
    prevMax.distance > current.distance ? prevMax : current
  );

  const [initial, age] = winner.ageGender.split('.');
  const gender = (function(initial: string) {
    switch (initial) {
      case 'M':
        return 'Male';
      case 'F':
        return 'Female';
      default:
        return '';
    }
  })(initial);
  return { age, gender };
}

function ageGenderKeysUnion(objects: IByAgeGender[]) {
  return Array.from(
    new Set(
      objects.reduce(
        (acc: string[], valueObject) => [
          ...acc,
          ...Object.keys(valueObject).filter(key => {
            const [age, gender] = key.split('.'); // remove unknown gender keys and 13-17 to remove statistical outliers
            return !(age === 'U' || gender === '13-17');
          })
        ],
        []
      )
    )
  );
}
