import { Grid, List, ListItem, Paper, Typography, Icon } from '@material-ui/core';
import * as React from 'react';

import { ICity } from '../../../lib/FBInsights';
import styled from '../../../lib/styled-components';
import { appTheme, extendedTheme } from '../../../lib/theme';

import PieChart, { PieChartData } from 'react-minimal-pie-chart';
import { Lock } from '@material-ui/icons';

const PIE_COLORS = [
  '#6141FF',
  '#6FC6EF',
  '#CE6DE7',
  '#FFCE53',
  '#65DF8F',
  '#FF4F4F',
  '#B276B2',
  '#4D4D4D',
  '#DECF3F',
  '#F15854'
];

export interface IProps {
  topCities: ICity[];
}
interface IState {}
export class TopCities extends React.Component<IProps, IState> {
  state: IState = {
    totalAudienceEngagement: null,
    averageConversionRate: null,
    topThreePosts: [],
    resultsByAgeAndGender: {}
  };

  chartData = (): PieChartData[] => {
    const { topCities } = this.props;
    const output: PieChartData[] = [];

    topCities.slice(0, 4).forEach((city, index) => {
      output.push({
        title: `${city.count} - ${city.name}`,
        value: city.count,
        color: PIE_COLORS[index]
      });
    });

    const otherCount = topCities.slice(5).reduce((acc, city) => {
      return acc + city.count;
    }, 0);

    output.push({ title: `${otherCount} - Other`, value: otherCount, color: PIE_COLORS[4] });

    return output;
  };

  public render() {
    const { topCities } = this.props;
    const chartData = this.chartData();

    return (
      <React.Fragment>
        <Typography variant="h6" gutterBottom>
          Top Cities
        </Typography>
        <StyledPaper>
          <Grid container>
            <SingleBox item sm={12}>
              {topCities && topCities.length > 0 ? (
                <React.Fragment>
                  <Grid container>
                    <Grid item sm={6}>
                      <PieChart
                        data={chartData}
                        label={({ data, dataIndex }) =>
                          Math.round(data[dataIndex].percentage) + '%'
                        }
                        labelStyle={{
                          fontSize: '5px',
                          fontFamily: 'sans-serif',
                          fill: '#121212'
                        }}
                        radius={37}
                        lineWidth={12}
                        paddingAngle={3}
                        labelPosition={112}
                      />
                    </Grid>
                    <ChartLegend item sm={6}>
                      {chartData.map(chartLine => (
                        <ChartLine key={`chart-data-line-${chartLine.title}`}>
                          <Dot style={{ background: chartLine.color }} />
                          <ChartTitles variant="caption">{chartLine.title}</ChartTitles>
                        </ChartLine>
                      ))}
                    </ChartLegend>
                  </Grid>
                </React.Fragment>
              ) : (
                'Insufficient Data'
              )}
            </SingleBox>
            <LockBox item sm={12}>
              <Lock />
            </LockBox>
          </Grid>
        </StyledPaper>
      </React.Fragment>
    );
  }
}

const ChartLegend = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ChartLine = styled.div`
  margin-bottom: 8px;
`;

const ChartTitles = styled(Typography)`
  display: inline-block;
`;

const Dot = styled.span`
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
`;

const SingleBox = styled(Grid)`
  ${appTheme.breakpoints.up('sm')} {
    height: 300px;
    display: flex;
    flex-direction: column;
  }
  overflow: auto;
  padding: 32px;
  &:first-child {
    border-bottom: 1px solid ${extendedTheme.borderColor};
  }
`;

const LockBox = styled(SingleBox)`
  justify-content: center;
  align-items: center;
`;

const StyledPaper = styled(Paper)`
  display: flex;
  flex-wrap: wrap;
`;
