import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';
import { JssProvider } from 'react-jss';
import { createGenerateClassName, jssPreset } from '@material-ui/core/styles';

import { create } from 'jss';
import { ErrorBoundary } from './components/atoms/ErrorBoundary';
import * as Sentry from '@sentry/browser';

declare var __COMMITHASH__: string;

process.env.NODE_ENV === 'production' &&
  Sentry.init({
    dsn: 'https://8e265c2824df44bcaa07753b06af6fbd@sentry.io/1382649',
    release: __COMMITHASH__
  });

const generateClassName = createGenerateClassName();
const jss = create({
  ...jssPreset(),
  // We define a custom insertion point that JSS will look for injecting the styles in the DOM.
  insertionPoint: 'jss-insertion-point'
});

ReactDOM.render(
  <ErrorBoundary>
    <JssProvider jss={jss} generateClassName={generateClassName}>
      <App />
    </JssProvider>
  </ErrorBoundary>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
