import { Attr, BelongsTo, HasMany, Model } from 'spraypaint';

import { Answer, Quiz } from './';
import ApplicationRecord from './ApplicationRecord';

export enum EOfferFormat {
  Motivational = 'motivational',
  Link = 'link',
  PersonalityLink = 'personality-link',
  PersonalityMotivational = 'peraonality-motivational'
}

export interface ILink {
  src: string;
  text: string;
  new_tab?: boolean;
}

@Model()
export class Offer extends ApplicationRecord {
  static jsonapiType = 'offers';

  @BelongsTo() quiz: Quiz;
  @HasMany() answers: Answer[];

  @Attr() id: string;
  @Attr() format: string;
  @Attr()
  range: {
    min: number;
    max: number;
  };
  @Attr()
  outcome: {
    text: string;
    description: string;
    image: {
      original: string;
    };
  };
  @Attr()
  links: ILink[];
}
