import { Grid, OutlinedInput, Typography } from '@material-ui/core';
import * as React from 'react';

import {
  adAccountConversionRate,
  IAdAccount,
  IByAgeGender,
  ICity,
  IPage,
  IPost,
  pageEngagementRateByAgeGender,
  pageTopCities,
  pageTopThreeMostEngagingPosts,
  pageTotalAudienceEngagement,
} from '../../../lib/FBInsights';
import styled from '../../../lib/styled-components';
import { PerformanceOverview } from './PerformanceOverview';
import { TopAudienceSegments } from './TopAudienceSegments';
import { TopCities } from './TopCities';

interface IProps {
  facebookToken: string;
  selectedAdAccount: IAdAccount;
  selectedPage: IPage;
}

interface IState {
  sessionLoaded: boolean;
  adAccountConversionRateByAgeGender?: IByAgeGender;
  adAccountTotalConversionRate?: number | null;
  engagementRateByAgeGender?: IByAgeGender;
  topAudienceSegments: { a: any };
  topThreePosts: IPost[];
  totalAudienceEngagement?: number | null;
  topCities: ICity[];
}

export class DashboardContainer extends React.Component<IProps, IState> {
  state: IState = {
    sessionLoaded: false,
    topThreePosts: [],
    topAudienceSegments: {
      a: {}
    },
    topCities: []
  };

  componentDidMount() {
    this.loadData();
  }

  async loadData() {
    const { selectedPage, selectedAdAccount } = this.props;

    const [
      totalAudienceEngagement,
      topThreePosts,
      { adAccountConversionRateByAgeGender, adAccountTotalConversionRate },
      engagementRateByAgeGender,
      topCities
    ] = await Promise.all([
      pageTotalAudienceEngagement(selectedPage),
      pageTopThreeMostEngagingPosts(selectedPage),
      adAccountConversionRate(selectedAdAccount),
      pageEngagementRateByAgeGender(selectedPage),
      pageTopCities(selectedPage)
    ]);

    this.setState({
      totalAudienceEngagement,
      topThreePosts,
      adAccountTotalConversionRate,
      adAccountConversionRateByAgeGender,
      engagementRateByAgeGender,
      topCities
    });
  }

  public render() {
    const {
      adAccountConversionRateByAgeGender,
      adAccountTotalConversionRate,
      engagementRateByAgeGender,
      topThreePosts,
      totalAudienceEngagement,
      topCities
    } = this.state;

    const { selectedAdAccount, selectedPage } = this.props;

    return (
      <React.Fragment>
        <PageNames variant="h5">
          <span title="Facebook AdAccount">{selectedAdAccount.name}</span> +{' '}
          <span title="Facebook Page">{selectedPage.name}</span>
        </PageNames>
        <Grid container spacing={16}>
          <Grid item sm={7}>
            <TopAudienceSegments
              key={`top-audience-segments-${selectedPage.id}-${selectedAdAccount.id}`}
              adAccountConversionRateByAgeGender={adAccountConversionRateByAgeGender}
              engagementRateByAgeGender={engagementRateByAgeGender}
            />
          </Grid>
          <Grid item sm={5}>
            <TopCities
              key={`top-audience-segments-${selectedPage.id}-${selectedAdAccount.id}`}
              topCities={topCities}
            />
          </Grid>
          <Grid item sm={12}>
            <PerformanceOverview
              key={`performance-overview-${selectedPage.id}-${selectedAdAccount.id}`}
              page={selectedPage}
              adAccount={selectedAdAccount}
              totalAudienceEngagement={totalAudienceEngagement}
              averageConversionRate={adAccountTotalConversionRate}
              topThreePosts={topThreePosts}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const PageNames = styled(Typography)`
  position: absolute;
  top: 84px;
`;

const StyledOutlinedInput = styled(OutlinedInput)`
  width: 200px;
`;
